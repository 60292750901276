import React, { useEffect, useState, useRef } from "react"
import { useTranslation } from "gatsby-plugin-react-i18next-nocookie"
import Ticker from "react-ticker"
import { LinkAnim } from "./link"
import ReactHoverObserver from "react-hover-observer"
import { useWindowSize } from "../hooks/windowsize"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import gsap from "gsap"

const AppTicker = ({ theme }) => {
  const { t } = useTranslation()
  const [move, setMove] = useState(true)
  const size = useWindowSize()
  const ref = useRef()
  const tl = useRef()

  const img = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "booknow.jpg" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  useEffect(() => {
    tl.current = gsap.timeline({ paused: true })
    tl.current.fromTo(
      ref.current,
      {
        x: "-100%",
        y: "50%",
        rotation: 30,
        opacity: 0,
      },
      {
        x: "-50%",
        y: "-50%",
        rotation: 0,
        opacity: 1,
        duration: 0.5,
      }
    )
    const tilemine = tl.current
    return () => {
      tilemine.kill()
    }
  }, [])

  const onHoverChanged = ({ isHovering }) => {
    setMove(!isHovering)
    size.width > 991 && toggleImage(isHovering)
  }

  const toggleImage = isHovering => {
    isHovering ? tl.current.play() : tl.current.reverse()
  }

  return (
    <div className="tickerContainer">
      <ReactHoverObserver
        hoverDelayInMs={300}
        hoverOffDelayInMs={300}
        onHoverChanged={onHoverChanged}
      >
        {({ isHovering }) => (
          <>
            <div className={`tickerWrapper ${theme}`} role="presentation">
              <Ticker speed={10} move={move} direction="toRight" offset="100%">
                {({ index }) => (
                  <>
                    <LinkAnim to="/book">{t("Book Now")}</LinkAnim>
                  </>
                )}
              </Ticker>
            </div>
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                opacity: 0,
              }}
              ref={ref}
            >
              <Img fixed={img.file.childImageSharp.fixed} />
            </div>
          </>
        )}
      </ReactHoverObserver>
    </div>
  )
}

export default AppTicker
